import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"

const HistoryPage = (props) => (
  <Layout  location={props.location}>
    <SEO title="Istoric modificări sait" />
    <h1>Istoric modificări</h1>
      <p>
          <strong>
              Ultima modificare: <br/>
          </strong>
          <br/>
          9 Sept 2019, versiunea 0.5 - modificată schema de culori
          <br/>
          <br/>
      </p>
      <br/>
      <p><strong>Istoric modificări:</strong></p>
        <ul>
            <li>
                7 Sept 2019, versiunea 0.4 - posibilitatea de a analiza mai multe părți de vorbire
            </li>
            <li>
                5 Sept 2019, versiunea 0.3 - a fost adăugată secțiunea de forum
            </li>
            <li>
                3 Sept 2019, versiunea 0.2 - a fost integrat un modul de <i>web analitycs</i>
            </li>
            <li>
                1 Sept 2019, versiunea 0.1 - prima variantă a saitului
            </li>
        </ul>

  </Layout>
)

export default HistoryPage
